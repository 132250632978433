<template>
  <div>
    <div :id="`balance-${componentId}`" :role="isCurrentBalanceRoute ? '' : 'button'"
      class="d-flex flex-column rounded border-primary px-half" :class="resolveCssByAmount"
      @click="goToDetails"
    >
      <div class="px-half text-center" style="font-size: 10px;">
        Credits
      </div>
      <span class="text-center mb-0">
        {{ getCurrencyFormat(currentCredit) }}</span>
    </div>
    <dx-util-popover
      v-if="!isCurrentBalanceRoute"
      :width="300" :visible="false"
      :target="`#balance-${componentId}`" position="bottom"
      show-event="dxhoverstart"
      hide-event="dxhoverend"
    >
      Click to view the details of the balance.
    </dx-util-popover>
  </div>
</template>

<script>
import { BalanceTypesEnum } from '@/enums'
// import customerBalanceService from '@/http/requests/finance/customerBalanceService'
import useCurrentUser from '@/libs/app/current-user'
import { currencyFormatter } from '@core/utils/filter'
import { v4 as uuidv4 } from 'uuid'
import useCustomerBalance from './useCustomerBalance'

export default {
  computed: {
    isCurrentBalanceRoute() {
      return this.$route.name === 'route-customer-balance'
    },
    resolveCssByAmount() {
      let cssValue = 'bg-light-danger border-danger'
      if (this.currentCredit > 0) {
        cssValue = 'bg-light-warning border-warning'
      }
      if (this.currentCredit > 100) {
        cssValue = 'bg-light-success border-success'
      }
      return cssValue
    },
    componentId() {
      return uuidv4()
    },
  },
  setup() {
    const { userCompanyId } = useCurrentUser()
    const { currentCredit, getCurrentBalance } = useCustomerBalance()
    return { userCompanyId, currentCredit, getCurrentBalance }
  },
  mounted() {
    this.getCurrentBalance(this.userCompanyId, BalanceTypesEnum.SHIPPING.value)
  },
  methods: {
    goToDetails() {
      this.$router.push({ name: 'route-customer-balance' })
    },
    getCurrencyFormat(data) {
      return currencyFormatter(data)
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
