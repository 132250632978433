import customerBalanceService from '@/http/requests/finance/customerBalanceService'
import {
  ref, // computed, watch, computed, reactive, onMounted,
} from '@vue/composition-api'

const currentCredit = ref(0)

export default function useCustomerBalance() {
  function getCurrentBalance(companyId, balanceType) {
    const balanceRequest = {
      companyId: companyId,
      balanceType: balanceType,
    }

    customerBalanceService.getBalanceData(balanceRequest).then(res => {
      currentCredit.value = res.data.body?.balance || 0
    })
  }

  return {
    currentCredit, getCurrentBalance,
  }
}
